import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';


function TrenitaliastudentPage(props) {
    var myRet;

    useEffect(() => {
    }, []);



    if (!props.apiAuthName)
        myRet = null;
    else {
        myRet = (
            <React.Fragment>
                <h2 className="title-page">Trenitalia Welfare Plus 20%</h2>
                <br />
                <p>                
                Nell’ambito del Programma Trenitalia for Business, Trenitalia ha il piacere di riservare agli Studenti
                iscritti all’Università degli Studi di Milano-Bicocca l’offerta Welfare PLUS 20%, 
                uno speciale sconto del 20% valido per l’acquisto di biglietti per viaggi privati degli Studenti 
                con un massimo di 6 accompagnatori.
                </p>
                <p>
                Lo sconto si applica ai biglietti emessi ad offerte Base, Economy e Super Economy sui treni Frecciarossa, 
                Frecciargento e Frecciabianca per tutti gli acquisti effettuati fino al 31 dicembre 2024.
                </p>
                <p>
                Per usufruire dello sconto Welfare Plus 20% è necessario:
                </p>
                <ol>
                <li>Essere titolare di una CartaFRECCIA Trenitalia</li>
                <li>Associare la propria CartaFRECCIA al programma Trenitalia for Business</li>
                </ol>
                <p>
                Si ricorda che l’associazione della propria CartaFRECCIA al programma Trenitalia for Business è 
                strettamente riservato agli Studenti regolarmente iscritti all’Università.
                </p>
                <p>
                Per iscriverti al programma CartaFRECCIA e/o associare la CartaFRECCIA segui le istruzioni contenute 
                nella guida allegata, utilizzando il link sottostante.
                </p>
                <p>
                Per maggiori informazioni entra nella tua area riservata e consulta la brochure. 
                </p>
                
                <a href="./trenitalia/trenitalia_welfare_plus_2024.pdf">Brochure Trenitalia Welfare Plus</a>

                <br /><br /><br />

                <Button href={process.env.REACT_APP_URL_TRENITALIASTUDENT}>
                    Area riservata per accedere allo sconto</Button>{' '}


            </React.Fragment >
        );

    }

    return myRet;
}

export default TrenitaliastudentPage;
